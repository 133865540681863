<div *ngIf="paginatedProfiles" [ngClass]="{'d-flex flex-column h-100': openInDashboard}">
  <ngx-datatable [ngClass]="{'dashboardTableClass' : openInDashboard}" *ngIf="!isLoadingTable; else spinner" #userTable
    class="material" [rows]="paginatedProfiles" [loadingIndicator]="false" [columns]="columns" [columnMode]="'force'"
    headerHeight="auto" [rowHeight]="openInDashboard ? 'auto' : 'auto'" [reorderable]="false" [externalPaging]="true"
    [scrollbarV]="openInDashboard ? false : false" [count]="this.pagination.page.totalElements"
    [offset]="this.pagination.page.pageNumber" [scrollbarH]="true" [limit]="this.pagination.page.size"
    (page)='setPage($event)'>
    <ngx-datatable-row-detail [rowHeight]="'auto'" #myDetailRow>
      <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
        <div style="padding-left:35px;">
          <ng-container *ngIf="row && row.type ==='phishing'">
            <div *ngIf="row && row.campaignStats && row.campaignStats?.length > 0" class="d-flex flex-column">
              <div class="d-flex justify-content-between w-100 align-items-center">
                <strong>{{ 'Campañas recibidas por el usuario'}}</strong>
              </div>
            </div>
            <div class="d-flex gap flex-column flex-grow-1">
              <ng-container *ngFor="let r of row.campaignStats; let i = index">
                <div class="w-100 d-flex  justify-content-between align-items-center">
                  <span class="w-25">
                    <strong>{{ i+1 + '. ' }}</strong>
                  </span>
                  <div class="d-flex flex-grow-1 flex-column">
                    <span class="w-100">
                      <strong>{{ 'Tipo de ataque de la campaña: ' }}</strong>{{ r.attackSubtype }}
                    </span>
                    <span class="w-100">
                      <strong>{{ 'Objetivo campaña: ' }}</strong>{{ mappedPersonality[r.mainPersonality] }}
                    </span>
                    <span class="w-100">
                      <strong>{{ 'Lanzamiento de la campaña: ' }}</strong>{{ r.startDate | date:'dd/MM/yyyy' }}
                    </span>
                    <span class="w-100">
                      <strong>{{ 'Cayó en la campaña: ' }}</strong><span [ngClass]="{'text-success': !r.clicked, 'text-danger': r.clicked}">{{ r.clicked ? 'Sí' : 'No' }}</span>
                    </span>
                  </div>  
  
                </div>
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="row && row.type ==='contextual'">
            <div *ngIf="row && row.answers?.length" class="d-flex flex-column">
              <div class="d-flex justify-content-between w-100 align-items-center">
                <strong>{{ 'Respuestas del formulario "' + row.answers[0].name + '"'}}</strong>
                <span class="mr-2">
                  <strong>{{ 'Fecha respuesta: ' }}</strong>
                  {{ row.answers[0].completedDate | date:'dd/MM/yyyy' }}
                </span>
              </div>
            </div>
            <div class="d-flex gap flex-column flex-grow-1">
              <ng-container *ngFor="let r of row.answers[0].data; let i = index">
                <div class="w-100 d-flex  justify-content-between align-items-center">
                  <span class="w-25">
                    <strong>{{ i+1 + '. ' }}</strong>
                  </span>
                  <div class="d-flex flex-grow-1 flex-column">
                    <span class="w-100">
                      <strong>{{ 'Pregunta: ' }}</strong>{{ r.code }}
                    </span>
                    <span class="w-100">
                      <strong>{{ 'Respuesta: ' }}</strong>{{ r.label }}
                    </span>
                  </div>
  
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </ng-template>
    </ngx-datatable-row-detail>
    <ngx-datatable-column *ngFor="let col of columns" [width]="getColumnWidth(col.prop)"
      [flexGrow]="getColumnFlexGrow(col.prop)" [resizeable]="col.prop !== 'email'"
      [cellClass]="'d-flex align-items-center'"
      [headerClass]="col.prop === 'contextual' ? 'mt-2 d-flex align-items-center text-center justify-content-center' :'mt-2 d-flex align-items-center'"
      [name]="col.name" [prop]="col.prop"
      [sortable]="(col.prop === 'storytelling' || col.prop === 'vulnerability' || col.prop === 'phishing') ? false: true">
      <ng-template ngx-datatable-header-template let-column="column">
        <ng-container *ngIf="col.prop === 'storytelling' || col.prop === 'phishing'; else defaultColumn">
          <ng-container *ngIf="col.prop === 'storytelling'; else phishingHeader">
            <div class="d-flex justify-content-center">{{ column.name}}</div>
            <div class="w-100  gap d-flex justify-content-around ">
              <div class="text-center d-flex align-items-center">
                <nb-icon icon="eye-outline" size="small"></nb-icon>
              </div>
              <div>/</div>
              <div class="text-center d-flex align-items-center">
                <nb-icon icon="eye-off-2-outline" size="small"></nb-icon>
              </div>
            </div>
          </ng-container>
          <ng-template #phishingHeader>
            <div class="d-flex justify-content-center">{{ column.name}}</div>
            <div class="w-100 gap d-flex justify-content-around">
              <div class="text-center d-flex align-items-center">
                {{ 'Sí'}}
              </div>
              <div>/</div>
              <div class="text-center d-flex align-items-center">
                {{ 'No'}}
              </div>
            </div>
          </ng-template>
        </ng-container>
        <ng-template #defaultColumn>
          {{ column.name }}
        </ng-template>
      </ng-template>
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
        <div class="d-flex w-100  justify-content-start align-items-center gap"
          [ngClass]="{'justify-content-center': col.prop === 'vulnerability'}">
          <span class="font-weigth-bold w-100">
            <ng-container *ngIf="col.prop === 'createdTimestamp'">
              {{ value | date:'dd/MM/yyyy HH:mm:ss' }}
            </ng-container>
            <ng-container *ngIf="col.prop === 'role'">
              {{ roles[value] }}
            </ng-container>
            <ng-container *ngIf="col.prop === 'personalizado'">
              <div class="d-flex justify-content-center align-items-center">
                <ng-container *ngIf="row?.role !== 'eve-admin'">
                  <div *ngIf="getPersonality(row); else personalityLabels"
                    class="mx-1 text-center d-flex align-items-center">
                    {{ getPersonality(row)}}
                  </div>
                  <ng-template #personalityLabels>
                    <div class="d-flex flex-column align-items-center justify-content-center">
                      <nb-tag-list class="d-flex justify-content-center flex-column">
                        <nb-tag class="whitespace-nowrap w-75 d-flex justify-content-center" size="tiny" status="info"
                          nbTooltip="Nivel de BIS" appearance="filled" [text]="getTagsText(row?.bisLevel, 'bis')">
                        </nb-tag>
                        <nb-tag class="whitespace-nowrap w-75 d-flex justify-content-center" size="tiny" status="info"
                          nbTooltip="Nivel de BAS" appearance="filled" [text]="getTagsText(row?.basLevel, 'bas')">
                        </nb-tag>
                        <nb-tag class="whitespace-nowrap w-75 d-flex justify-content-center" size="tiny" status="info"
                          nbTooltip="Nivel de NC" appearance="filled" [text]="getTagsText(row?.ncLevel, 'nc')">
                        </nb-tag>
                      </nb-tag-list>
                    </div>
                  </ng-template>
                </ng-container>
              </div>
            </ng-container>
            <ng-container *ngIf="col.prop === 'vulnerability'">
              <div class="d-flex w-100 gap justify-content-center align-items-center">
                <ng-container
                  *ngIf="(row.vulnerabilityPredictions && row.vulnerabilityPredictions.length === 1 && getFirstVulnerability(row) === '0.00'); else vulnerability">
                  <div class="without-contextual">
                    {{ 'Pendiente test contextual'}}
                  </div>
                </ng-container>
                <ng-template #vulnerability>
                  <div class="d-flex flex-column">
                    <span class="w-100 d-flex justify-content-around"
                      *ngIf="row.basLevel && row.basLevel !== '0' && row.vulnerabilityPredictions && row.vulnerabilityPredictions.length > 0"
                      class="first-vulnerability">
                      <span>{{ getFirstVulnerability(row) + "%"}}</span>
                      <span class="px-2"></span>
                      <span [ngClass]="getFirstColor(row)">{{ mappingVulnerabilitiesText[getFirstVulnerabilityLevel(row)]
                        + ""}}</span>
                    </span>
                    <span class="w-100 d-flex align-items-center justify-content-center"
                      *ngIf="row.basLevel && row.basLevel !== '0' && row.vulnerabilityPredictions && row.vulnerabilityPredictions.length > 0"
                      class=" date-vulnerability">{{ "(" + getFirstVulnerabilityDate(row) + ")"}}</span>
                  </div>
                  <div [nbTooltip]="'Vulnerabilidades anteriores'">
                    <button
                      *ngIf="row.basLevel && row.basLevel !== '0' && row.vulnerabilityPredictions && row.vulnerabilityPredictions.length > 1"
                      nbButton [nbPopover]="popoverTemplate" size="tiny" nbPopoverTrigger="click"
                      class="d-flex align-items-center justify-content-center" shape="round" status="info" hero><nb-icon
                        icon="activity-outline"></nb-icon></button>
                  </div>
                  <ng-template #popoverTemplate>
                    <div class=" p-4 popover-content">
                      <h3 class="pb-2">{{ 'Vulnerabilidades anteriores'}}</h3>
                      <div class="d-flex gap justify-content-around flex-column">
                        <div *ngFor="let v of getPreviousVulnerabilities(row); let i=index"
                          class="d-flex justify-content-around align-items-center">
                          <div class="font-weigth-bold">{{ getFixed(v.vulnerabilityScore) + "%"}}</div>
                          <span
                            class="w-100 px-2 d-flex align-items-end justify-content-start date-vulnerability-popover">{{
                            "(" + getVulnerabilityDate(v.date) + ")"}}</span>
                          <div class="font-weigth-bold" [ngClass]="getColor(v.vulnerabilityScoreText)">{{
                            mappingVulnerabilitiesText[v.vulnerabilityScoreText]}}</div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ng-template>
              </div>

            </ng-container>
            <ng-container *ngIf="col.prop === 'storytelling'">
              <div *ngIf="row?.basLevel && row.basLevel !== '0' && row?.role !== 'eve-admin'"
                class="d-flex w-100 justify-content-around align-items-center">
                <div class="d-flex justify-content-center w-50 font-weigth-bold text-success"
                  [nbTooltip]="'Microhistorias completadas'">{{ row.seenStorytellingCount}}</div>
                <div>/</div>
                <div class="d-flex justify-content-center w-50 font-weigth-bold text-danger"
                  [nbTooltip]="'Microhistorias disponibles'">{{ row.unseenStorytellingCount}}</div>
              </div>
            </ng-container>
            <ng-container *ngIf="col.prop === 'contextual'">
              <div *ngIf="row.contextualData" class="d-flex w-100 justify-content-around align-items-center">
                <button *ngIf="row.contextualData" (click)="toggleExpandRow(row, 'contextual')" [nbTooltip]="'Ver respuestas'"
                  nbButton outline shape="round" status="info">{{row.contextualData.label}}</button>

                <div [nbTooltip]="'Histórico contextuales'">
                  <button nbButton [nbPopover]="contextualHistorical" (click)="openContextualPopover(row)" size="tiny"
                    nbPopoverTrigger="click" class="d-flex align-items-center justify-content-center" shape="round"
                    status="info" hero><nb-icon icon="activity-outline"></nb-icon></button>
                </div>
                <ng-template #contextualHistorical>
                  <ng-container *ngIf="previousContextuals">
                    <div class=" p-4 popover-content">
                      <h3 class="pb-2">{{ 'Contextuales anteriores'}}</h3>
                      <div class="d-flex gap justify-content-around flex-column">
                        <div *ngFor="let v of previousContextuals; let i=index"
                          class="d-flex justify-content-around align-items-center">
                          <div class="d-flex flex-column font-weigth-bold">{{ 'Valor contextual:'}} <strong>{{ v.contextualVariableSum ? v.contextualVariableSum : 0}}</strong></div>
                          <span
                            class="w-100 px-2 d-flex align-items-end justify-content-start">{{
                            "(" + (v.completedDate | date: 'dd/MM/yyyy' ) + ")"}}</span>
                         
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-template>
              </div>
              <div>
                <div class=""></div>
              </div>
            </ng-container>
            <ng-container *ngIf="col.prop === 'phishing'">
              <div *ngIf="row?.basLevel && row.basLevel !== '0' && row?.role !== 'eve-admin'"
                class="d-flex w-100 justify-content-around align-items-center">
                <div class="d-flex justify-content-center w-50 font-weigth-bold text-danger"
                  [nbTooltip]="'Campañas caídas'">{{ row.clickedCampaignsCount}}</div>
                <div>/</div>
                <div class="d-flex justify-content-center w-50 font-weigth-bold text-success"
                  [nbTooltip]="'Campañas no caídas'">{{ row.unclickedCampaignsCount}}</div>
              </div>
              <div *ngIf="openInDashboard" class="w-100 d-flex justify-content-center">
                <button nbButton [nbTooltip]="'Registro campañas'" (click)="toggleExpandRow(row, 'phishing')" size="tiny"
                class="d-flex align-items-center justify-content-center" shape="round"
                status="info" hero><nb-icon icon="activity-outline"></nb-icon></button>
              </div>
            </ng-container>
            <ng-container *ngIf="col.prop === 'department'">
              <span *ngIf="row?.department !== '0'">
                {{ value }}
              </span>
            </ng-container>
            <ng-container
              *ngIf="col.prop !== 'role' && col.prop !== 'createdTimestamp' && col.prop !== 'personalizado' && col.prop !== 'department'">
              {{ value }}
            </ng-container>

          </span>
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-row-detail #rowDetail [rowHeight]="'auto'">
      <ng-template let-row="row" ngx-datatable-row-detail-template>
        <div class="custom-expanded-row">
          <p>Información adicional de {{ row.name }}</p>
        </div>
      </ng-template>
    </ngx-datatable-row-detail>
    <ngx-datatable-column [width]="getColumnWidth('actions')" *ngIf="!openInDashboard"
      [flexGrow]="getColumnFlexGrow('actions')" [headerClass]="'mt-2'" name="Operaciones" prop="operaciones"
      [sortable]="false" [resizeable]="false" [cellClass]="'centered-cell'">
      <ng-template let-rowIndex="rowIndex" ngx-datatable-header-template let-name="name" let-value="value"
        let-row="row">
        <div class="d-flex justify-content-center">
          Acciones
        </div>
      </ng-template>
      <ng-template let-rowIndex="rowIndex" ngx-datatable-cell-template let-value="value" let-row="row">
        <div class="d-flex justify-content-center align-items-center">
          <!-- Botones en fila -->
          <ng-container *ngIf="row?.role !== 'eve-admin'">
            <div *ngFor="let button of getButtonsActionRow()" class="mx-1 d-flex align-items-center">
              <!-- <button *ngIf="row.role !== 'eve-admin' && row.email !== userData.email " class="px-0 custom-hover" nbButton ghost type="button" [shape]="'round'" size="medium"
                (click)="clickButton(button, row)" [status]="button.color">{{button.text}}</button> -->
              <ng-container *ngIf="button.id !== 2; else deleteBtn">
                <button class="px-0  button-action custom-hover" nbButton ghost type="button" [nbTooltip]="button.text"
                  [shape]="'round'" size="medium" (click)="clickButton(button, row)" [status]="button.color">
                  <nb-icon [icon]="button.icon" pack="eva"></nb-icon>
                </button>
              </ng-container>
              <ng-template #deleteBtn>
                <button [nbSpinner]="deletingItem === row" nbSpinnerStatus="info" nbSpinnerSize="large"
                  [nbTooltip]="button.text" nbSpinnerMessage="" class="px-0 button-action custom-hover" nbButton ghost
                  type="button" [shape]="'round'" size="medium" (click)="clickButton(button, row)"
                  [status]="button.color">
                  <span [ngClass]="deletingItem === row ? 'hide-text-button' : 'show-text-button'">
                    <nb-icon [icon]="button.icon" pack="eva"></nb-icon>
                  </span>
                </button>
              </ng-template>

              <!-- <button *ngIf="row.role !== 'eve-admin' && row.email !== userData?.email " [nbTooltip]="button.text"
                nbTooltipClass="custom-tooltip" nbTooltipPlacement="top" class="px-0 custom-hover" nbButton ghost type="button" [shape]="'round'" size="medium"
                (click)="clickButton(button, row)" [status]="button.color"><nb-icon [icon]="button.icon" pack="eva"></nb-icon></button> -->
            </div>
          </ng-container>
        </div>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
  <ng-container *ngIf="paginatedProfiles && paginatedProfiles?.length > 0">
    <div class="d-flex justify-content-between">
      <div class="d-flex justify-content-between mt-3 align-items-center w-100">
        <span>Total de registros: {{ pagination.page.totalElements }}</span>
      </div>
      <div class="d-flex flex-wrap justify-content-center justify-content-sm-end mt-3 align-items-center w-100">
        <div class="d-flex">
          <span class=" d-flex align-items-center pr-2">{{ 'Ir a '}}</span>
          <nb-select filled shape="round" [status]="'info'" class="pr-2" [(selected)]="pagination.page.pageNumber"
            (selectedChange)="onPageSelect($event)">
            <nb-option *ngFor="let page of [].constructor(pagination.page.totalPages); let i = index" [value]="i">{{ i +
              1
              }}</nb-option>
          </nb-select>
        </div>
        <div class="d-flex align-items-center">
          <nb-icon class="pag-icon" status="info" (click)="prevPage()" icon="arrow-ios-back-outline"></nb-icon>
          <div class="d-flex justify-content-center align-items-center">
            <span>{{ (pagination.page.pageNumber + 1) + ' / ' + pagination.page.totalPages }}</span>
          </div>
          <nb-icon class="pag-icon" status="info" (click)="nextPage()" icon="arrow-ios-forward-outline"></nb-icon>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- <div class="d-flex justify-content-center mt-3 align-items-center w-100">
    <nb-icon class="pag-icon" status="info" (click)="prevPage()" icon="arrow-ios-back-outline"></nb-icon>
    <div class="d-flex justify-content-center align-items-center">
      <span *ngFor="let page of pagination.getPageNumbers()" (click)="page !== '...' && setPage({offset: page-1})"
        [class.active-page]="page-1 === pagination.page.pageNumber" class="page-number">
        {{ page }}
      </span>
    </div>
    <nb-icon class="pag-icon" (click)="nextPage()" status="info" icon="arrow-ios-forward-outline"></nb-icon>
  </div> -->
  <ng-template #spinner>
    <div class="d-flex justify-content-center align-items-center w-100 h-100">
      <nb-spinner status="info" size="giant" [message]="''"></nb-spinner>
    </div>
  </ng-template>
</div>

<ng-template #deleteDialog let-data let-ref="dialogRef">
  <nb-card class="bg-light bg-gradient">
    <nb-card-body>
      <div class="p-5">
        <h3 class="d-flex justify-content-between align-items-center">
          {{'¿Estás seguro que quieres eliminar el usuario?' }}
        </h3>
        <div class="d-flex justify-content-around mt-5">
          <button nbButton status="info" size="small" shape="round" outline (click)="deleteLine(data); ref.close()">Si,
            eliminar</button>
          <button nbButton class="ml-2" size="small" (click)="ref.close()" status="info" shape="round">No,
            cancelar</button>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>