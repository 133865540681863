/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Base64FileDto } from '../models/base-64-file-dto';
import { CreatedUsersDto } from '../models/created-users-dto';
import { KeycloakUserDto } from '../models/keycloak-user-dto';
import { ReducedWorkspaceDto } from '../models/reduced-workspace-dto';
import { RegisterDto } from '../models/register-dto';
import { WorkspaceApiCredentialsDto } from '../models/workspace-api-credentials-dto';
import { WorkspaceModelDto } from '../models/workspace-model-dto';
import { WorkspaceNameListDto } from '../models/workspace-name-list-dto';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation workspaceControllerCreateDemoUser
   */
  static readonly WorkspaceControllerCreateDemoUserPath = '/workspace/demo/create-user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workspaceControllerCreateDemoUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  workspaceControllerCreateDemoUser$Response(params?: {

    /**
     * Username to impersonate
     */
    username?: string;
    workspace?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, WorkspaceService.WorkspaceControllerCreateDemoUserPath, 'get');
    if (params) {
      rb.query('username', params.username, {});
      rb.header('workspace', params.workspace, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `workspaceControllerCreateDemoUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  workspaceControllerCreateDemoUser(params?: {

    /**
     * Username to impersonate
     */
    username?: string;
    workspace?: string;
    context?: HttpContext
  }
): Observable<Array<string>> {

    return this.workspaceControllerCreateDemoUser$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation workspaceControllerFindByName
   */
  static readonly WorkspaceControllerFindByNamePath = '/workspace/findByName/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workspaceControllerFindByName()` instead.
   *
   * This method doesn't expect any request body.
   */
  workspaceControllerFindByName$Response(params: {

    /**
     * Name to get workspace info
     */
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ReducedWorkspaceDto>> {

    const rb = new RequestBuilder(this.rootUrl, WorkspaceService.WorkspaceControllerFindByNamePath, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReducedWorkspaceDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `workspaceControllerFindByName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  workspaceControllerFindByName(params: {

    /**
     * Name to get workspace info
     */
    name: string;
    context?: HttpContext
  }
): Observable<ReducedWorkspaceDto> {

    return this.workspaceControllerFindByName$Response(params).pipe(
      map((r: StrictHttpResponse<ReducedWorkspaceDto>) => r.body as ReducedWorkspaceDto)
    );
  }

  /**
   * Path part for operation workspaceControllerFindByEmail
   */
  static readonly WorkspaceControllerFindByEmailPath = '/workspace/findByEmail/{email}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workspaceControllerFindByEmail()` instead.
   *
   * This method doesn't expect any request body.
   */
  workspaceControllerFindByEmail$Response(params: {

    /**
     * Email to get workspaces associated
     */
    email: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<WorkspaceNameListDto>> {

    const rb = new RequestBuilder(this.rootUrl, WorkspaceService.WorkspaceControllerFindByEmailPath, 'get');
    if (params) {
      rb.path('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WorkspaceNameListDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `workspaceControllerFindByEmail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  workspaceControllerFindByEmail(params: {

    /**
     * Email to get workspaces associated
     */
    email: string;
    context?: HttpContext
  }
): Observable<WorkspaceNameListDto> {

    return this.workspaceControllerFindByEmail$Response(params).pipe(
      map((r: StrictHttpResponse<WorkspaceNameListDto>) => r.body as WorkspaceNameListDto)
    );
  }

  /**
   * Path part for operation workspaceControllerGetAllWorkspaceInfo
   */
  static readonly WorkspaceControllerGetAllWorkspaceInfoPath = '/workspace/admin/all-info';

  /**
   * Gets all info from workspace. Only workspace admin can use this endpoint.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workspaceControllerGetAllWorkspaceInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  workspaceControllerGetAllWorkspaceInfo$Response(params?: {
    workspace?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<WorkspaceModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, WorkspaceService.WorkspaceControllerGetAllWorkspaceInfoPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WorkspaceModelDto>;
      })
    );
  }

  /**
   * Gets all info from workspace. Only workspace admin can use this endpoint.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `workspaceControllerGetAllWorkspaceInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  workspaceControllerGetAllWorkspaceInfo(params?: {
    workspace?: string;
    context?: HttpContext
  }
): Observable<WorkspaceModelDto> {

    return this.workspaceControllerGetAllWorkspaceInfo$Response(params).pipe(
      map((r: StrictHttpResponse<WorkspaceModelDto>) => r.body as WorkspaceModelDto)
    );
  }

  /**
   * Path part for operation workspaceControllerGetKeycloakUsersInWorkspace
   */
  static readonly WorkspaceControllerGetKeycloakUsersInWorkspacePath = '/workspace/admin/all-keycloak-users';

  /**
   * Gets all keycloak users from workspace. Only workspace admin can use this endpoint.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workspaceControllerGetKeycloakUsersInWorkspace()` instead.
   *
   * This method doesn't expect any request body.
   */
  workspaceControllerGetKeycloakUsersInWorkspace$Response(params?: {

    /**
     * Page number
     */
    page?: number;

    /**
     * Items limit per page
     */
    limit?: number;

    /**
     * User email
     */
    email?: string;

    /**
     * Vulnerability level filter
     */
    vulnerabilityLevelFilter?: 'low' | 'medium' | 'high';
    workspace?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<KeycloakUserDto>>> {

    const rb = new RequestBuilder(this.rootUrl, WorkspaceService.WorkspaceControllerGetKeycloakUsersInWorkspacePath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('email', params.email, {});
      rb.query('vulnerabilityLevelFilter', params.vulnerabilityLevelFilter, {});
      rb.header('workspace', params.workspace, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<KeycloakUserDto>>;
      })
    );
  }

  /**
   * Gets all keycloak users from workspace. Only workspace admin can use this endpoint.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `workspaceControllerGetKeycloakUsersInWorkspace$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  workspaceControllerGetKeycloakUsersInWorkspace(params?: {

    /**
     * Page number
     */
    page?: number;

    /**
     * Items limit per page
     */
    limit?: number;

    /**
     * User email
     */
    email?: string;

    /**
     * Vulnerability level filter
     */
    vulnerabilityLevelFilter?: 'low' | 'medium' | 'high';
    workspace?: string;
    context?: HttpContext
  }
): Observable<Array<KeycloakUserDto>> {

    return this.workspaceControllerGetKeycloakUsersInWorkspace$Response(params).pipe(
      map((r: StrictHttpResponse<Array<KeycloakUserDto>>) => r.body as Array<KeycloakUserDto>)
    );
  }

  /**
   * Path part for operation workspaceControllerSendReminderMailUsersWithCompulsoryResource
   */
  static readonly WorkspaceControllerSendReminderMailUsersWithCompulsoryResourcePath = '/workspace/admin/send-reminder-mail-compulsory-resource';

  /**
   * Send reminder mail to users with compulsory resources assigned. Only workspace admin can use this endpoint.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workspaceControllerSendReminderMailUsersWithCompulsoryResource()` instead.
   *
   * This method doesn't expect any request body.
   */
  workspaceControllerSendReminderMailUsersWithCompulsoryResource$Response(params?: {
    workspace?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, WorkspaceService.WorkspaceControllerSendReminderMailUsersWithCompulsoryResourcePath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Send reminder mail to users with compulsory resources assigned. Only workspace admin can use this endpoint.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `workspaceControllerSendReminderMailUsersWithCompulsoryResource$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  workspaceControllerSendReminderMailUsersWithCompulsoryResource(params?: {
    workspace?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.workspaceControllerSendReminderMailUsersWithCompulsoryResource$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation workspaceControllerSendReminderMailNonCategorizedUsers
   */
  static readonly WorkspaceControllerSendReminderMailNonCategorizedUsersPath = '/workspace/admin/send-reminder-mail-non-categorized-users';

  /**
   * Send reminder mail non categorized users. Only workspace admin can use this endpoint.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workspaceControllerSendReminderMailNonCategorizedUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  workspaceControllerSendReminderMailNonCategorizedUsers$Response(params?: {
    workspace?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, WorkspaceService.WorkspaceControllerSendReminderMailNonCategorizedUsersPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Send reminder mail non categorized users. Only workspace admin can use this endpoint.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `workspaceControllerSendReminderMailNonCategorizedUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  workspaceControllerSendReminderMailNonCategorizedUsers(params?: {
    workspace?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.workspaceControllerSendReminderMailNonCategorizedUsers$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation workspaceControllerGetPendingUsersInWorkspace
   */
  static readonly WorkspaceControllerGetPendingUsersInWorkspacePath = '/workspace/admin/pending-users';

  /**
   * Gets all keycloak users from workspace that needs confirmation. Only workspace admin can use this endpoint.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workspaceControllerGetPendingUsersInWorkspace()` instead.
   *
   * This method doesn't expect any request body.
   */
  workspaceControllerGetPendingUsersInWorkspace$Response(params?: {

    /**
     * Page number
     */
    page?: number;

    /**
     * Items limit per page
     */
    limit?: number;
    workspace?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<KeycloakUserDto>>> {

    const rb = new RequestBuilder(this.rootUrl, WorkspaceService.WorkspaceControllerGetPendingUsersInWorkspacePath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.header('workspace', params.workspace, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<KeycloakUserDto>>;
      })
    );
  }

  /**
   * Gets all keycloak users from workspace that needs confirmation. Only workspace admin can use this endpoint.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `workspaceControllerGetPendingUsersInWorkspace$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  workspaceControllerGetPendingUsersInWorkspace(params?: {

    /**
     * Page number
     */
    page?: number;

    /**
     * Items limit per page
     */
    limit?: number;
    workspace?: string;
    context?: HttpContext
  }
): Observable<Array<KeycloakUserDto>> {

    return this.workspaceControllerGetPendingUsersInWorkspace$Response(params).pipe(
      map((r: StrictHttpResponse<Array<KeycloakUserDto>>) => r.body as Array<KeycloakUserDto>)
    );
  }

  /**
   * Path part for operation workspaceControllerEnableWorkspace
   */
  static readonly WorkspaceControllerEnableWorkspacePath = '/workspace/admin/enable-workspace';

  /**
   * Creates non existing users and deletes non matching users. Set a random user password and send an email to every user created. Only workspace admin can use this endpoint.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workspaceControllerEnableWorkspace()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workspaceControllerEnableWorkspace$Response(params: {
    workspace?: string;
    context?: HttpContext
    body: Array<RegisterDto>
  }
): Observable<StrictHttpResponse<CreatedUsersDto>> {

    const rb = new RequestBuilder(this.rootUrl, WorkspaceService.WorkspaceControllerEnableWorkspacePath, 'post');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreatedUsersDto>;
      })
    );
  }

  /**
   * Creates non existing users and deletes non matching users. Set a random user password and send an email to every user created. Only workspace admin can use this endpoint.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `workspaceControllerEnableWorkspace$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workspaceControllerEnableWorkspace(params: {
    workspace?: string;
    context?: HttpContext
    body: Array<RegisterDto>
  }
): Observable<CreatedUsersDto> {

    return this.workspaceControllerEnableWorkspace$Response(params).pipe(
      map((r: StrictHttpResponse<CreatedUsersDto>) => r.body as CreatedUsersDto)
    );
  }

  /**
   * Path part for operation workspaceControllerDownloadProfileCreationTemplate
   */
  static readonly WorkspaceControllerDownloadProfileCreationTemplatePath = '/workspace/admin/download-profile-creation-template';

  /**
   * Downloads the excel template for user creation. Only workspace admin can use this endpoint.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workspaceControllerDownloadProfileCreationTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  workspaceControllerDownloadProfileCreationTemplate$Response(params?: {
    workspace?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Base64FileDto>> {

    const rb = new RequestBuilder(this.rootUrl, WorkspaceService.WorkspaceControllerDownloadProfileCreationTemplatePath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Base64FileDto>;
      })
    );
  }

  /**
   * Downloads the excel template for user creation. Only workspace admin can use this endpoint.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `workspaceControllerDownloadProfileCreationTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  workspaceControllerDownloadProfileCreationTemplate(params?: {
    workspace?: string;
    context?: HttpContext
  }
): Observable<Base64FileDto> {

    return this.workspaceControllerDownloadProfileCreationTemplate$Response(params).pipe(
      map((r: StrictHttpResponse<Base64FileDto>) => r.body as Base64FileDto)
    );
  }

  /**
   * Path part for operation workspaceControllerCreateUsers
   */
  static readonly WorkspaceControllerCreateUsersPath = '/workspace/admin/users';

  /**
   * Creates users in the user workspace. Only workspace admin can use this endpoint.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workspaceControllerCreateUsers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workspaceControllerCreateUsers$Response(params: {
    workspace?: string;
    context?: HttpContext
    body: Array<RegisterDto>
  }
): Observable<StrictHttpResponse<CreatedUsersDto>> {

    const rb = new RequestBuilder(this.rootUrl, WorkspaceService.WorkspaceControllerCreateUsersPath, 'post');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreatedUsersDto>;
      })
    );
  }

  /**
   * Creates users in the user workspace. Only workspace admin can use this endpoint.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `workspaceControllerCreateUsers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workspaceControllerCreateUsers(params: {
    workspace?: string;
    context?: HttpContext
    body: Array<RegisterDto>
  }
): Observable<CreatedUsersDto> {

    return this.workspaceControllerCreateUsers$Response(params).pipe(
      map((r: StrictHttpResponse<CreatedUsersDto>) => r.body as CreatedUsersDto)
    );
  }

  /**
   * Path part for operation workspaceControllerDeleteUserInWorkspaceByUuid
   */
  static readonly WorkspaceControllerDeleteUserInWorkspaceByUuidPath = '/workspace/admin/users/{email}/delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workspaceControllerDeleteUserInWorkspaceByUuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  workspaceControllerDeleteUserInWorkspaceByUuid$Response(params: {

    /**
     * Email user
     */
    email: string;
    workspace?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, WorkspaceService.WorkspaceControllerDeleteUserInWorkspaceByUuidPath, 'delete');
    if (params) {
      rb.path('email', params.email, {});
      rb.header('workspace', params.workspace, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `workspaceControllerDeleteUserInWorkspaceByUuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  workspaceControllerDeleteUserInWorkspaceByUuid(params: {

    /**
     * Email user
     */
    email: string;
    workspace?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.workspaceControllerDeleteUserInWorkspaceByUuid$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation workspaceControllerUpdateWorkspaceMicrosoftCredentials
   */
  static readonly WorkspaceControllerUpdateWorkspaceMicrosoftCredentialsPath = '/workspace/{uuid}/update-microsoft-credentials';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workspaceControllerUpdateWorkspaceMicrosoftCredentials()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workspaceControllerUpdateWorkspaceMicrosoftCredentials$Response(params: {
    context?: HttpContext
    body: WorkspaceApiCredentialsDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, WorkspaceService.WorkspaceControllerUpdateWorkspaceMicrosoftCredentialsPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `workspaceControllerUpdateWorkspaceMicrosoftCredentials$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workspaceControllerUpdateWorkspaceMicrosoftCredentials(params: {
    context?: HttpContext
    body: WorkspaceApiCredentialsDto
  }
): Observable<void> {

    return this.workspaceControllerUpdateWorkspaceMicrosoftCredentials$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
