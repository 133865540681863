import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { PaginationService } from "../../../../../services/services/pagination.service";
import { BType } from "../../../account.interfaces";
import {
  AnsweredQuestionsTimelineDto,
  ProfileModelDto,
  WorkspaceModelDto,
} from "../../../../../services/api/models";
import { CoreProvider } from "../../../../../services/services/core.provider";
import { ActivatedRoute, Router } from "@angular/router";
import { ComponentsService } from "../../../../../services/services/components.service";
import { Subscription } from "rxjs";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { SidebarService } from "../../../../../services/services/sidebar.service";
import { error } from "console";

@Component({
  selector: "ngx-users-table",
  templateUrl: "./users-table.component.html",
  styleUrls: ["./users-table.component.scss"],
})
export class UsersTableComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("userTable") userTable: DatatableComponent;
  isLoadingTable: boolean = false;
  isExpanded = false;
  previousContextuals: any[];
  // pagination = {
  //   page: {
  //     pageNumber: 0,
  //     pageSize: 5,
  //     totalElements: 0,
  //     totalPages: 0,
  //   }
  // };
  expandedRow: any = null; // Almacena la fila expandida
  paginatedProfiles: any[] = [];

  @Input() pagination: PaginationService;
  @Output("refreshPage") refreshPage: EventEmitter<any> = new EventEmitter();

  @ViewChild("deleteDialog") deleteDialogRef: TemplateRef<any>;
  @ViewChild("duplicateDialog") duplicateDialogRef: TemplateRef<any>;
  @ViewChild("confirmLaunch") confirmLaunchRef: TemplateRef<any>;
  @ViewChild("launchConfirmation") launchConfirmation: TemplateRef<any>;
  @ViewChild("endConfirmation") endConfirmation: TemplateRef<any>;

  roles = {
    "eve-user": "Usuario",
    "eve-admin": "Admin",
  };

  mappedPersonality = {
    'bis': 'BIS',
    'bas': 'BAS',
    'bis_nc': 'BIS-NC',
    'bas_nc': 'BAS-NC',
  }
  mappingVulnerabilitiesText = {
    high: "Alto",
    medium: "Medio",
    low: "Bajo",
  };
  buttonsAction: BType[] = [
    // {
    //   id: 0,
    //   text: "Detalle",
    //   tooltipText: "Ver el detalle del usuario",
    // },
    {
      id: 1,
      text: "Editar",
      icon: "edit-outline",
      tooltipText: "Editar usuario",
    },
    {
      id: 2,
      text: "Eliminar",
      icon: "trash-2-outline",
      tooltipText: "Eliminar usuario",
    },
  ];

  @Input() set rows(value) {
    if (value) {
      this.deletingItem = null;
      this.dataRows = value;
      // for (let index = 0; index < 2; index++) {

      //   value.forEach(element => {
      //     this.dataRows.push(element)

      //   });

      // }

      this.updatePagination();
    }
  }
  dataRows: any[];
  @Input() columns: any[];
  @Input() openInDashboard: boolean;
  @Output("refreshTable") refreshTable: EventEmitter<void> = new EventEmitter();
  deletingItem = null;
  userData: ProfileModelDto;
  userDataSubscription: Subscription;
  constructor(
    private core: CoreProvider,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private componentsService: ComponentsService,
    private sidebarService: SidebarService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  async ngOnInit(): Promise<void> {
    this.userDataSubscription = this.core.getUserData().subscribe({
      next: (data: ProfileModelDto | null) => {
        if (data) {
          this.userData = data;
        }
      },
      error: (error: any) => {
        console.error("Error al obtener los datos del usuario", error);
      },
    });

    this.sidebarService.sidebarVisible$.subscribe((visible) => {
      this.isLoadingTable = true;
      setTimeout(() => {
        this.isLoadingTable = false;
      }, 100);
    });
  }
  ngAfterViewInit() {
    // Escuchar cambios en la ventana
    // window.addEventListener('resize', () => this.recalculate());
  }

  ngOnDestroy(): void {
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }
  }

  async clickButton(button, itm) {
    switch (button.id) {
      case 0:
        this.router.navigate([
          `/account/workspace/${this.route.snapshot.params.uuid}/users/${itm.uuid}`,
        ]);
        break;
      case 1:
        this.router.navigate(["/account/profile"], {
          queryParams: { email: itm.email },
        }); //this.router.navigate([`/account/workspace/${itm.uuid}/users`]);
        break;
      case 2:
        if (!this.deletingItem) {
          this.componentsService.openModal(this.deleteDialogRef, itm, true);
        }

        break;
      default:
        break;
    }
  }

  deleteLine(data) {
    if (data) {
      this.deletingItem = data;
      this.core.api.workspace
        .workspaceControllerDeleteUserInWorkspaceByUuid({ email: data.email })
        .subscribe({
          next: (res) => {
            this.componentsService.showToast(
              "success",
              "Usuario eliminado con éxito",
              "Ok!"
            );
            this.refreshTable.emit();
          },
          error: (error) => {
            this.deletingItem = null;
            this.componentsService.showToast(
              "danger",
              "Hemos tenido un problema al eliminar el usuario. Inténtalo de nuevo por favor.",
              "Uf..."
            );
          },
        });
    }
  }

  getButtonsActionRow() {
    return this.buttonsAction;
  }

  getUpperCase(status) {
    return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
  }

  goToDetail(row) {
    this.router.navigate(["/account/campaign/campaign-detail"], {
      queryParams: { uuid: row.uuid },
    });
  }

  onPageSelect(pageNumber: number) {
    this.pagination.page.pageNumber = pageNumber;
    this.refreshPage.emit();
  }

  prevPage() {
    if (this.pagination.page.pageNumber > 0) {
      this.pagination.prevPage();
      this.refreshPage.emit();
    }
  }

  nextPage() {
    if (this.pagination.page.pageNumber < this.pagination.page.totalPages - 1) {
      this.pagination.nextPage();
      this.refreshPage.emit();
    }
  }

  updatePagination(filter?) {
    // this.pagination.page.totalElements = this.dataRows.length;
    this.pagination.page.totalPages = Math.ceil(
      this.pagination.page.totalElements / this.pagination.page.size
    );
    this.pagination.page.pageNumber = Math.max(
      0,
      Math.min(
        this.pagination.page.pageNumber,
        this.pagination.page.totalPages - 1
      )
    );
    if (!filter) {
      this.paginateProfiles();
    }
  }

  paginateProfiles() {
    // const startIndex = this.pagination.page.pageNumber * this.pagination.page.size;
    // const endIndex = startIndex + this.pagination.page.size;

    this.paginatedProfiles = this.dataRows;
  }
  getPersonality(row) {
    if (row.bisLevel === "0" && row.basLevel === "0" && row.ncLevel === "0") {
      return "Categorizado";
    } else if (!row.bisLevel && !row.basLevel && !row.ncLevel) {
      return "No categorizado";
    } else {
      return false;
    }
  }

  getTagsText(row, prop) {
    let text: string;
    if (prop === "bis") {
      if (row === "high") {
        text = "BIS - Alto";
      } else if (row === "low") {
        text = "BIS - Bajo";
      } else if (row === "midlow") {
        text = "BIS - Medio Bajo";
      } else if (row === "midhigh") {
        text = "BIS - Medio Alto";
      }
    } else if (prop === "bas") {
      if (row === "high") {
        text = "BAS - Alto";
      } else if (row === "low") {
        text = "BAS - Bajo";
      } else if (row === "midlow") {
        text = "BAS - Medio Bajo";
      } else if (row === "midhigh") {
        text = "BAS - Medio Alto";
      }
    } else {
      if (row === "high") {
        text = "NC - Alto";
      } else if (row === "low") {
        text = "NC - Bajo";
      } else if (row === "midlow") {
        text = "NC - Medio Bajo";
      } else if (row === "midhigh") {
        text = "NC - Medio Alto";
      }
    }
    return text;
  }

  getColumnWidth(prop: string): number | undefined {
    const columnWidths: { [key: string]: number } = {
      email: 300, // Se ajusta dinámicamente
      storytelling: 150,
      phishing: 100,
      operaciones: 250,
      actions: 100,
      department: 170,
      role: 100,
    };
    return columnWidths[prop] || 150; // Ancho por defecto más estrecho
  }

  getColumnFlexGrow(prop: string): number {
    const flexGrowValues: { [key: string]: number } = {
      email: 2, // Crece más
      storytelling: 1, // Crece moderado
      operaciones: 1, // Crece moderado
      phishing: 1, // Crece moderado
    };
    return flexGrowValues[prop] || 0; // Por defecto, no crece
  }

  // recalculate() {
  //   if (this.userTable) {
  //     this.sidebarService.sidebarVisible$.subscribe((visible) => {
  //       if (!visible) {
  //         this.isLoadingTable = true;

  //         this.userTable.recalculate()
  //         setTimeout(() => {
  //           this.isLoadingTable = false;
  //         }, 1000);
  //       }
  //     })
  //   }
  // }

  getPreviousVulnerabilities(row) {
    if (
      !row ||
      !row.vulnerabilityPredictions ||
      row.vulnerabilityPredictions.length < 2
    ) {
      return []; // Si hay menos de 2 elementos, no hay suficientes para mostrar
    }

    const vulns = row.vulnerabilityPredictions;

    if (vulns.length <= 4) {
      // Si tiene entre 2 y 4 elementos, omitir la primera y devolver las restantes
      return vulns.slice(1);
    } else {
      // Si tiene más de 4 elementos, devolver la 2ª, 3ª y 4ª (omitimos la primera)
      return vulns.slice(1, 4);
    }
  }

  getFirstVulnerability(row) {
    if (
      row &&
      row.vulnerabilityPredictions &&
      row.vulnerabilityPredictions.length > 0
    ) {
      const num = parseFloat(
        row.vulnerabilityPredictions[0].vulnerabilityScore
      );
      return isNaN(num) ? "0.00" : num.toFixed(2);
    }
  }

  getFixed(value: any) {
    const num = parseFloat(value);
    return isNaN(num) ? "0.00" : num.toFixed(2);
  }

  getColor(value: string) {
    if (value) {
      switch (this.mappingVulnerabilitiesText[value]) {
        case "Bajo":
          return "text-success";
        case "Medio":
          return "text-warning";
        case "Alto":
          return "text-danger";
      }
    }
  }
  getFirstColor(row) {
    if (
      row &&
      row.vulnerabilityPredictions &&
      row.vulnerabilityPredictions.length > 0
    ) {
      let value = row.vulnerabilityPredictions[0].vulnerabilityScoreText;
      if (value) {
        switch (this.mappingVulnerabilitiesText[value]) {
          case "Bajo":
            return "text-success";
          case "Medio":
            return "text-warning";
          case "Alto":
            return "text-danger";
        }
      }
    }    
    
  }
  
  getFirstVulnerabilityDate(row) {
    if (
      row &&
      row.vulnerabilityPredictions &&
      row.vulnerabilityPredictions.length > 0
    ) {
      const date = new Date(
        row.vulnerabilityPredictions[0].date
      );
      return isNaN(date.getTime()) ? "--/--/--" : date.toLocaleDateString("es-ES");
    }
  }

  getVulnerabilityDate(date) {
    if (date) {
      return  new Date(date).toLocaleDateString("es-ES");
    }
  }

  getFirstVulnerabilityLevel(row) {
    if (
      row &&
      row.vulnerabilityPredictions &&
      row.vulnerabilityPredictions.length > 0
    ) {
      const level = row.vulnerabilityPredictions[0].vulnerabilityScoreText
      return level;
    }
  }

  toggleExpandRow(row, type) {
    if (type) {
      row.type = type;
    }
    // Si la fila actual ya está expandida, la colapsamos sin llamar al servicio.

    if (this.expandedRow === row) {
      this.userTable.rowDetail.toggleExpandRow(row);
      this.expandedRow = null;
      this.isExpanded = false;
      return;
    }
  
    // Si hay otra fila expandida, la cerramos antes de expandir la nueva.
    if (this.expandedRow) {
      this.userTable.rowDetail.toggleExpandRow(this.expandedRow);
    }
  
    // Llamamos al servicio para obtener los datos y expandimos la nueva fila.
    if (row.type === 'contextual') {
    this.core.api.resource.resourceControllerGetAnsweredQuestionsByChannelResource({ uuid: row.contextualData.uuid })
      .subscribe({
        next: (res: AnsweredQuestionsTimelineDto[]) => {
          row.answers = res;
          console.log('row', row);
          
          this.userTable.rowDetail.toggleExpandRow(row);
          this.expandedRow = row;
          this.isExpanded = true;
        },
        error: () => {
          this.componentsService.showToast(
            "danger",
            "Hemos tenido un problema al obtener las respuestas del contextual. Inténtalo de nuevo por favor.",
            "Uf..."
          );
        }
      });
    } else {
      this.userTable.rowDetail.toggleExpandRow(row);
      this.expandedRow = row;
      this.isExpanded = true;
    }
  }
  

  setPage(event: any) {
    console.log('Cambiando a página:', event.offset); // Muestra la página seleccionada
    this.pagination.page.pageNumber = event.offset; // Actualiza el número de página
    this.refreshTable.emit(); // Carga los datos de la nueva página
  }

  openContextualPopover (row) {
    console.log('row', row)
    this.core.api.resource.resourceControllerGetAnsweredQuestionsByChannelResource({profileUuid: row.uuid}).subscribe({
      next: (res:AnsweredQuestionsTimelineDto[]) => {      
        console.log('res',res)
        this.previousContextuals = res;
       
       
      }, error: (error) => {
        this.componentsService.showToast(
          "danger",
          "Hemos tenido un problema al obtener el histórico de contextuales. Inténtalo de nuevo por favor.",
          "Uf..."
        );
      }
  })
}
}
