<div class="w-100 flex-grow-1 container-info-modal d-flex flex-column justify-content-end p-4">
  <div class="d-flex justify-content-between">
    <h2>{{ title }}</h2>
    <img (click)="closeModalInfo()" class="close-gray" src="assets/imgs/close-thin-gray.svg">
  </div>
  <section class="d-flex  flex-grow-1 section-text justify-content-center">
    <!-- Tipos de graph -->
    <!-- Gráfico tarta -->
    <ngx-dashboard-pie-graph *ngIf="graphType == 'pie'" [isCentral]="false" [colorScheme]="colorScheme"
      [graphData]="graphData" [mainContainerId]="'modal__graphPie'" [isVulnerability]="isVulnerability">
    </ngx-dashboard-pie-graph>

    <!-- Gráfico líneas múltiple -->
    <ngx-multiple-linear *ngIf="graphType == 'multipleLine'" [colorScheme]="colorScheme" [graphData]="graphData"
      [isVulnerability]="isVulnerability" [isLinear]="isLinear" [mainContainerId]="'modal__graphMultipleLine'"
      [displayInModal]="true" [monthMode]="monthMode" class="w-100 h-100">
    </ngx-multiple-linear>

    <!-- Gráfico de barras -->
    <ngx-bar-chart *ngIf="graphType == 'bar'" [colorScheme]="colorScheme" [graphData]="graphData"
      [isVulnerability]="isVulnerability" [mainContainerId]="'modal__graphBar'" [displayInModal]="true"
      class="w-100 h-100">
    </ngx-bar-chart>

    <!-- <ngx-accumulated-bars-graph class="w-100" [fullView]="true" [colorScheme]="colorScheme" [isVulnerability]="true"
    [graphData]="graphData"></ngx-accumulated-bars-graph> -->
  </section>
  <p>{{ graphExplanation }}</p>
  <div *ngIf="showLegend">
    <div class="d-flex c-gap justify-content-center align-items-center flex-wrap">
      <div class="legend-item">
        <div class="legend-square" [style]="'background-color: ' + colorScheme.domain[isVulnerability ? 2 : 3] + ';'">
        </div>
        <div>Baja</div>
      </div>
      <div class="legend-item" *ngIf="!isVulnerability">
        <div class="legend-square" [style]="'background-color: ' + colorScheme.domain[2] + ';'"></div>
        <div>Medio Baja</div>
      </div>
      <div class="legend-item" *ngIf="isVulnerability">
        <div class="legend-square" [style]="'background-color: ' + colorScheme.domain[1] + ';'"></div>
        <div>Media</div>
      </div>
      <div class="legend-item" *ngIf="!isVulnerability">
        <div class="legend-square" [style]="'background-color: ' + colorScheme.domain[1] + ';'"></div>
        <div>Medio Alta</div>
      </div>
      <div class="legend-item">
        <div class="legend-square" [style]="'background-color: ' + colorScheme.domain[0] + ';'"></div>
        <div>Alta</div>
      </div>
    </div>
  </div>
</div>