<div *ngIf="results" class="d-flex position-relative flex-column justify-center align-items-center w-100 h-100">
  <!-- Chart antiguo -->
  <!-- <ngx-charts-pie-chart
        [scheme]="colorScheme"
        [results]="results"
        [labels]="true"
        [explodeSlices]="false"
  >
  </ngx-charts-pie-chart> -->

  <!-- D3 Chart -->
  <div #chartContainer [id]="mainContainerId" (window:resize)="onResize()"
    class="w-100 h-100 d-flex align-items-end pieContainer"></div>
  <div id="tooltip-{{mainContainerId}}" class="tooltip-pie"></div>

  <nb-card class="legend" *ngIf="isVulnerability">
    <nb-card-header>
      <h3 class="d-flex align-items-center"><nb-icon [nbPopover]="templateTooltip" nbPopoverTrigger="click"
          nbPopoverPlacement="top" [nbPopoverOffset]="10" icon="info-outline" pack="eva"></nb-icon><span
          class="ml-2">{{'Leyenda:'}}</span></h3>
    </nb-card-header>
    <nb-card-body>
      <div class="d-flex justify-content-start align-itemscenter">
        <span class="mr-2">{{'Nivel Bajo '}}</span><strong><nb-icon class="popover-icon" icon="less-than" pack="fa"></nb-icon>{{' 33,33%'}}</strong>
      </div>
      <div class="d-flex justify-content-start align-itemscenter">
        <span>{{ 'Nivel Medio '}}<span><strong><nb-icon class="popover-icon" icon="greater-than-equal" pack="fa"></nb-icon>{{' 33,34%'}}</strong> <span>{{' y '}}</span>
            <strong>   
              <nb-icon class="popover-icon" icon="less-than-equal" pack="fa"></nb-icon>{{'  66,66%'}}</strong></span></span>
      </div>
      <div class="d-flex justify-content-start align-items-center">
        <span class="mr-2">{{'Nivel Alto '}}</span><strong><nb-icon class="popover-icon" icon="greater-than" pack="fa"></nb-icon>{{'  66.67%'}}</strong>
      </div>
    </nb-card-body>
  </nb-card>
</div>

<ng-template #templateTooltip>
  <div class="tooltip-container">
    <div class="d-flex mb-2 align-items-center">
      <nb-icon class="popover-icon" icon="exclamation" pack="fa"></nb-icon>
      <span class="font-weight-bold">
        <strong>
          Información del Algoritmo sobre la evaluación de vulnerabilidades
        </strong>
      </span>
    </div>
    <div class="gap d-flex align-items-center">
      <nb-icon class="popover-icon" icon="diamond" pack="fa"></nb-icon>
      <span>
        <strong>
          Personalidad y Ciberseguridad:
        </strong>
      </span>
    </div>
    <span>
      Los resultados provienen del test de personalidad, donde se analizan tres dimensiones clave: 
      <strong>
        BIS (Sistema de Inhibición Conductual), BAS (Sistema de Activación Conductual) y NC (Necesidad de Cognición).
      </strong>
    </span>
    <div class="gap d-flex align-items-center">
      <nb-icon class="popover-icon" icon="diamond" pack="fa"></nb-icon>
      <span>
        <strong>
          Simulación de Ciberataques:
        </strong>
      </span>
    </div>
    <span>
      Los datos se recopilan a partir de simulaciones de phishing, diseñadas para evaluar el comportamiento del usuario ante posibles amenazas digitales.
    </span>
    <div class="gap d-flex align-items-center">
      <nb-icon class="popover-icon" icon="diamond" pack="fa"></nb-icon>
      <span>
        <strong>
          Factores Contextuales:
        </strong>
      </span>
    </div>
    <span>
      Se incluyen los resultados del test de variables contextuales, en el que los usuarios indican su situación y percepción al recibir una simulación, ayudando a identificar patrones de respuesta.

    </span>
    <div class="gap d-flex align-items-center">
      <nb-icon class="popover-icon" icon="diamond" pack="fa"></nb-icon>
      <span>
        <strong>
          Entrenamiento Adaptativo:
        </strong>
      </span>
    </div>
    <span>
      La vulnerabilidad del usuario varía en función de su entrenamiento con las microhistorias, adaptándose progresivamente para fortalecer su resiliencia ante ciberataques.
    </span>
  </div>
</ng-template>
<ng-template #textLegend></ng-template>

<ng-template #graphModal let-ref="dialogRef" let-data let-itm="itm">
  <nb-card class="overflow-auto big-modal w-100 h-100" [nbSpinner]="loadingModal"
    nbSpinnerStatus="info" nbSpinnerSize="large" nbSpinnerMessage="">
    <div *ngIf="!loadingModal" class="w-100 flex-grow-1 container-info-modal max-h-100 d-flex flex-column justify-content-end p-4">
      <div class="d-flex justify-content-between">
        <h2>{{ 'Perfiles con vulnerabilidad ' + vulnerabilityLevelMap[vulnerabilityLevelFilter] }}</h2>
        <img (click)="manageClosing(ref)"
          class="close-gray" src="assets/imgs/close-thin-gray.svg">
      </div>
      <section *ngIf="!loadingModal" class="d-flex flex-grow-1 section-text justify-content-center" >
        <!-- Recuento de anonimizados y filtros -->
        <div class="w-100 d-flex flex-column" [ngClass]="{'hidden' : loadingModal}">
          <div class="w-100 pb-2 d-flex align-items-center justify-content-center">
            <div class="d-flex justify-content-between flex-grow-1">
              <form [formGroup]="searchForm" class="col-3 d-flex align-items-center">
                <label for="filter" class="col-form-label mr-2">{{'Filtro'}}</label>
                <nb-form-field class="w-100">
                  <nb-icon nbPrefix icon="search-outline" pack="eva"></nb-icon>
                  <input type="text" formControlName="search" fullWidth shape="round" class="bg-white" nbInput
                    placeholder="Buscar...">
                </nb-form-field>
              </form>
              <p *ngIf="anonymizedUsersCount || anonymizedUsersCount === 0" class="mt-2 anonymyzedLabel font-size-giant font-weight-bold">{{ 'Anonimizados: '}} <span class="anonymyzedNumber">{{ anonymizedUsersCount }}</span></p>
              <p *ngIf="vulnerabilityLevelFilter" class="mt-2 font-weight-bold">{{ 'Total perfiles vulnerabilidad ' + vulnerabilityLevelMap[vulnerabilityLevelFilter] + ': ' + totalFilterUsers }}</p>
            </div>
            <div *ngIf="anonymizedUsersCount !== totalFilterUsers" class="d-flex justify-content-end align-items-center flex-grow-1">
              <label for="rowCount" class="col-form-label mr-2">{{'Mostrar'}}</label>
              <input type="number" shape="round" nbInput [fieldSize]="'small'" id="rowCount"
                [(ngModel)]="this.paginationService.page.size" (ngModelChange)="validateSize()" placeholder="Cantidad"
                class="form-control bg-white" min="1" step="1" oninput="this.value = Math.abs(Math.floor(this.value))">
            </div>
          </div>

          <!-- Tabla de usuarios -->
           <ng-container *ngIf="(anonymizedUsersCount || anonymizedUsersCount === 0) && anonymizedUsersCount !== totalFilterUsers">
             <ngx-users-table class="flex-grow-1" *ngIf="rows && rows.length > 0" [openInDashboard]="true" [pagination]="this.paginationService"
               (refreshPage)="updatePage($event)" [rows]="rows" [columns]="columns">
             </ngx-users-table>
           </ng-container>
           <div  class="modal-message" *ngIf="!loadingModal && anonymizedUsersCount !== totalFilterUsers">
            <div class="container-modal">
              <div class="d-flex mb-2 align-items-center">
                <nb-icon class="popover-icon" size="tiny" icon="exclamation" pack="fa"></nb-icon>
                <span class="font-weight-bold">
                  <strong>
                    Información del Algoritmo sobre la evaluación de vulnerabilidades
                  </strong>
                </span>
              </div>
              <div class="gap d-flex align-items-center">
                <nb-icon size="tiny" class="popover-icon" icon="diamond" pack="fa"></nb-icon>
                <span>
                  <strong>
                    Personalidad y Ciberseguridad:
                  </strong>
                </span>
              </div>
              <span>
                Los resultados provienen del test de personalidad, donde se analizan tres dimensiones clave: 
                <strong>
                  BIS (Sistema de Inhibición Conductual), BAS (Sistema de Activación Conductual) y NC (Necesidad de Cognición).
                </strong>
              </span>
              <div class="gap d-flex align-items-center">
                <nb-icon class="popover-icon" icon="diamond" pack="fa"></nb-icon>
                <span>
                  <strong>
                    Simulación de Ciberataques:
                  </strong>
                </span>
              </div>
              <span>
                Los datos se recopilan a partir de simulaciones de phishing, diseñadas para evaluar el comportamiento del usuario ante posibles amenazas digitales.
              </span>
              <div class="gap d-flex align-items-center">
                <nb-icon class="popover-icon" icon="diamond" pack="fa"></nb-icon>
                <span>
                  <strong>
                    Factores Contextuales:
                  </strong>
                </span>
              </div>
              <span>
                Se incluyen los resultados del test de variables contextuales, en el que los usuarios indican su situación y percepción al recibir una simulación, ayudando a identificar patrones de respuesta.
          
              </span>
              <div class="gap d-flex align-items-center">
                <nb-icon class="popover-icon" icon="diamond" pack="fa"></nb-icon>
                <span>
                  <strong>
                    Entrenamiento Adaptativo:
                  </strong>
                </span>
              </div>
              <span>
                La vulnerabilidad del usuario varía en función de su entrenamiento con las microhistorias, adaptándose progresivamente para fortalecer su resiliencia ante ciberataques.
              </span>

            </div>
          </div>
           <ng-container *ngIf="anonymizedUsersCount == totalFilterUsers">
             <div *ngIf="vulnerabilityLevelFilter && !loadingModal" class="d-flex justify-content-center align-items-center w-100 h-100 big">
               <p><strong>{{ '¡Los usuarios con vulnerabilidad ' + vulnerabilityLevelMap[vulnerabilityLevelFilter] + ' están anonimizados!' }}</strong></p>
             </div>
            </ng-container>
        </div>
      </section>
    </div>
  </nb-card>
</ng-template>