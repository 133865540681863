<div *ngIf="!isEmptyData; else emptyMessage" class="d-flex justify-center align-items-center w-100 h-100">
    <div #chartContainer [id]="mainContainerId" (window:resize)="onResize()" class="charContainer w-100 h-100">

    </div>
</div>

<ng-template #emptyMessage>
    <div class="w-100 h-100 d-flex justify-content-center align-items-center">
        <div class="font-weight-bold">
            {{ 'Aún no tengo datos que mostrarte'}}
        </div>
    </div>
</ng-template>