import { Injectable, TemplateRef } from "@angular/core";
import { KeycloakEventType, KeycloakService } from "keycloak-angular";
import { ApiService } from "./api.service";
import { environment } from "../../../environments/environment";
import {
  AdminDashboardDto,
  DashboardDto,
  ExtendedProfileModelDto,
  ProfileModelDto,
  ReducedWorkspaceDto,
  WorkspaceModelDto,
} from "../api/models";
import { BehaviorSubject, Observable, filter, firstValueFrom, tap } from "rxjs";
import { Router } from "@angular/router";
import { InitService } from "./init.service";
import { ComponentsService } from "./components.service";
import { BasicModalComponent } from "../../main-module/account-module/components/basic-modal/basic-modal.component";
import { NbDialogRef } from "@nebular/theme";

export const WORKSPACE: string | null =
  location.host
    .replace(environment.appBaseUrl, "")
    .replace(/\-$/g, "")
    .split("-")[0] ?? null;

@Injectable({
  providedIn: "root",
})
export class CoreProvider {
  public readonly workspace: string | null = WORKSPACE;
  private _userData = new BehaviorSubject<ExtendedProfileModelDto | null>(null);
  private _dashboard = new BehaviorSubject<
    DashboardDto | AdminDashboardDto | null
  >(null);
  private _workspace = new BehaviorSubject<ReducedWorkspaceDto | null>(null);

  private _isFetchingUserData = false;
  private _isFetchingDashboard = false;
  private _isFetchingWorkspace = false;

  loggedUserRequiredAction;
  private initialAlert: NbDialogRef<any>;

  resourceRedirection: boolean = false;
  hasShownDidntClickStorytellingModal: boolean = false;

  get roles(): string[] {
    if (!this.keycloak.isLoggedIn()) return [];
    return this.keycloak.getUserRoles();
  }

  constructor(
    public readonly keycloak: KeycloakService,
    public readonly initService: InitService,
    public readonly api: ApiService,
    public readonly router: Router,
    public readonly componentsService: ComponentsService
  ) {
    if (!environment.production) window["core"] = this;
    this.initService.initCore(this);

    this.keycloak.keycloakEvents$.subscribe(async (ev) => {
      if (this.keycloak.isLoggedIn()) this.getUserData();

      if (ev.type === KeycloakEventType.OnAuthSuccess && !environment.isDemo) {
        this.api.me.meControllerInitialAlert().subscribe({
          next: async (res) => {
            if (
              res.showAlert &&
              !(await firstValueFrom(this.getUserData())).requiredAction
            ) {
              this.initialAlert = this.componentsService.openModal(
                BasicModalComponent as unknown as TemplateRef<any>
              );
            }
          },
        });
      }
    });
    if (this.keycloak.isLoggedIn()) this.getUserData();
  }

  closeInitialAlert() {
    this.initialAlert?.close();
  }

  async logOut() {
    await this.keycloak.logout();
  }

  isUserAdmin(): boolean {
    return (
      this.keycloak.isLoggedIn() &&
      this.keycloak.getUserRoles().includes("eve-admin")
    );
  }

  getUserData(): Observable<ExtendedProfileModelDto | null> {
    if (!this._userData.value && !this._isFetchingUserData) {
      this._isFetchingUserData = true;
      this.fetchUserData().subscribe({
        complete: () => (this._isFetchingUserData = false),
      });
    }
    return this._userData.asObservable().pipe(filter((data) => data !== null));
  }

  fetchUserData(): Observable<ExtendedProfileModelDto> {
    return this.api.me.meControllerMe().pipe(
      tap({
        next: (data: ExtendedProfileModelDto) => {
          this._userData.next(data);
          this.loggedUserRequiredAction = data.requiredAction;
          this.initService.initUser();
        },
        error: (error: any) => {
          console.error("Error al obtener el perfil del usuario", error);
        },
      })
    );
  }

  getDashboardData(): Observable<DashboardDto | AdminDashboardDto | null> {
    if (!this._dashboard.value && !this._isFetchingDashboard) {
      this._isFetchingDashboard = true;
      this.fetchDashboardData().subscribe({
        complete: () => (this._isFetchingDashboard = false),
      });
    }
    return this._dashboard.asObservable().pipe(filter((data) => data !== null));
  }

  fetchDashboardData(): Observable<AdminDashboardDto | DashboardDto> {
    if (this.isUserAdmin()) {
      return this.api.me.meControllerAdminDashboard().pipe(
        tap({
          next: (data: AdminDashboardDto) => this._dashboard.next(data),
          error: (error: any) => {
            console.error("Error al obtener el dashboard del usuario", error);
          },
        })
      );
    } else {
      return this.api.me.meControllerDashboard().pipe(
        tap({
          next: (data: DashboardDto) => this._dashboard.next(data),
          error: (error: any) => {
            console.error("Error al obtener el dashboard del usuario", error);
          },
        })
      );
    }
  }
}
