import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  ProfilesGroupedByImprovedVulnerabilityLevelDto,
  ProfilesGroupedByPersonalityVarDto,
  ProfilesGroupedByVulnerabilityLevelDto,
} from "../../../../../services/api/models";
import * as d3 from "d3";
import tippy from "tippy.js";

@Component({
  selector: "ngx-multiple-linear",
  templateUrl: "./multiple-linear.component.html",
  styleUrl: "./multiple-linear.component.scss",
})
export class MultipleLinearComponent implements OnInit, AfterViewInit {
  @ViewChild("chartContainer") chartContainer: ElementRef;
  isEmptyData: boolean = false;
  @Input() graphData: Array<
    | ProfilesGroupedByVulnerabilityLevelDto
    | ProfilesGroupedByPersonalityVarDto
    | ProfilesGroupedByImprovedVulnerabilityLevelDto
  >;
  @Input() isVulnerability: boolean = false;
  @Input() monthMode: boolean = true;
  @Input() isLinear: boolean = false;

  @Input() mainContainerId: string;
  @Input() colorScheme: any;
  @Input() displayInModal: boolean = false;

  multi: Array<any> = [];
  width: number;
  height: number;
  margin = { top: 20, right: 30, bottom: 40, left: 30 };
  colorHover = "#daea7a";
  timerId;

  ngOnInit(): void {
    if (this.graphData && this.graphData.length > 0) {
      this.checkValues();
    } else {
      this.isEmptyData = true; 
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.width =
        this.chartContainer.nativeElement.offsetWidth -
        this.margin.left -
        this.margin.right;
      this.height =
        this.chartContainer.nativeElement.offsetHeight -
        this.margin.top -
        this.margin.bottom;

      this.renderSvg();
    }, 300);
  }

  public onResize() {
    this.timerId = setTimeout(() => {
      // Eliminamos el contenido del contenedor (de esta manera aseguramos que se eliminan también listeners)
      if (this.chartContainer) {
        while (this.chartContainer.nativeElement.firstChild) {
          this.chartContainer.nativeElement.removeChild(
            this.chartContainer.nativeElement.firstChild
          );
        }
  
        this.width =
          this.chartContainer.nativeElement.offsetWidth -
          this.margin.left -
          this.margin.right;
        this.height =
          this.chartContainer.nativeElement.offsetHeight -
          this.margin.top -
          this.margin.bottom;
  
        this.renderSvg();

      }
    }, 300);
  }

  renderSvg() {
    // Datos de prueba
    const testData = JSON.parse(
      '[{"name":"Alto Nivel","series":[{"name":"Enero","value":10},{"name":"Febrero","value":8},{"name":"Marzo","value":12},{"name":"Abril","value":15},{"name":"Mayo","value":7},{"name":"Junio","value":14},{"name":"Julio","value":10},{"name":"Agosto","value":11},{"name":"Septiembre","value":9},{"name":"Octubre","value":12},{"name":"Noviembre","value":6},{"name":"Diciembre","value":10}]},{"name":"Nivel Medio-Alto","series":[{"name":"Enero","value":9},{"name":"Febrero","value":7},{"name":"Marzo","value":10},{"name":"Abril","value":12},{"name":"Mayo","value":8},{"name":"Junio","value":11},{"name":"Julio","value":7},{"name":"Agosto","value":10},{"name":"Septiembre","value":8},{"name":"Octubre","value":11},{"name":"Noviembre","value":5},{"name":"Diciembre","value":9}]},{"name":"Nivel Medio-Bajo","series":[{"name":"Enero","value":4},{"name":"Febrero","value":3},{"name":"Marzo","value":5},{"name":"Abril","value":6},{"name":"Mayo","value":4},{"name":"Junio","value":7},{"name":"Julio","value":6},{"name":"Agosto","value":5},{"name":"Septiembre","value":3},{"name":"Octubre","value":6},{"name":"Noviembre","value":5},{"name":"Diciembre","value":4}]},{"name":"Bajo Nivel","series":[{"name":"Enero","value":2},{"name":"Febrero","value":4},{"name":"Marzo","value":3},{"name":"Abril","value":5},{"name":"Mayo","value":6},{"name":"Junio","value":4},{"name":"Julio","value":7},{"name":"Agosto","value":3},{"name":"Septiembre","value":5},{"name":"Octubre","value":7},{"name":"Noviembre","value":8},{"name":"Diciembre","value":6}]}]'
    );

    // Configuración de dimensiones y márgenes
    const margin = this.margin;
    const width = this.width;
    const height = this.height;
    const mainContainerSelector = "#" + this.mainContainerId;

    // Crear el contenedor SVG
    const svg = d3
      .select("#" + this.mainContainerId)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Configuración de datos
    const data = this.multi.map((group) => ({
      name: group.name,
      series: group.series.map((d) => ({
        name: d.name,
        value: d.value,
      })),
    }));

    // Crear lista de meses en base a los datos
    const months = data[0].series.map((d) => d?.name);

    // Escala X: usando escala de puntos para mostrar nombres de meses
    const x = d3.scalePoint().domain(months).range([0, width]).padding(0.5);

    // Escala Y
    const y = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d3.max(d.series, (s) => s.value))])
      .nice()
      .range([height, 0]);

    // Añadir ejes
    svg
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(x))
      .selectAll("text")
      .attr("transform", () => {
        if (this.displayInModal) {
          return "translate(0,0)";
        } else {
          return "translate(-10,10)rotate(-45)";
        }
      })
      .each(function (d) {
        // Agregar el atributo "title" a cada nombre del eje X
        d3.select(this).append("title").text(d); // Usa "d", que contiene el valor del mes
      });

    svg.append("g").call(d3.axisLeft(y));

    // Colores para las líneas
    const color = d3
      .scaleOrdinal()
      .domain(data.map((d) => d?.name))
      .range(this.colorScheme.domain); // Colores personalizables

    // Generador de línea
    const line = d3
      .line()
      .x((d) => x(d?.name))
      .y((d) => y(d?.value))
      .curve(d3.curveCatmullRom);

    // Dibujar líneas y puntos
    data.forEach((group, index) => {
      // Dibujar la línea
      svg
        .append("path")
        .datum(group.series)
        .attr("fill", "none")
        .attr("stroke", color(group.name))
        .attr("stroke-width", 1.5)
        .attr("d", line);

      // Dibujar los puntos sobre la línea
      svg
        .selectAll(`.point-${group.name.replace(/\s+/g, "-")}`)
        .data(group.series)
        .join("circle")
        .attr("class", `point-${group.name.replace(/\s+/g, "-")}`)
        .attr("cx", (d) => x(d?.name))
        .attr("cy", (d) => y(d?.value))
        .attr("r", 4)
        .attr("fill", color(group.name))
        .on("mouseover", (event, d) => {
          d3.select(event.currentTarget)
            .transition()
            .duration(200)
            .style("left", event.pageX + 10 + "px")
            .style("top", event.pageY - 20 + "px")
            .style("cursor", "pointer")
            .attr("fill", this.colorHover);
          tippy(event.currentTarget, {
            content: `<strong>${group.name}:</strong> ${d.value}`,
            placement: "top",
            theme: "light",
            arrow: true,
            delay: [100, 50],
            allowHTML: true,
          });
        })
        .on("mouseout", (event) => {
          d3.select(event.currentTarget)
            .transition()
            .duration(200)
            .attr("fill", color(group.name));
        });

      // Añadir etiquetas en línea con desplazamiento dinámico
      svg
        .selectAll(`.label-${group.name.replace(/\s+/g, "-")}`)
        .data([group.series[group.series.length - 1]])
        .join("text")
        .attr("x", (d) => x(d?.name) + 5)
        .attr("y", (d) => y(d?.value) + (index % 2 === 0 ? -10 : 10)) // Alterna posición arriba y abajo
        /* .attr('dy', index % 2 === 0 ? '-8px' : '8px') // Alterna la distancia vertical */
        .attr("fill", color(group.name))
        .text(group.name)
        .style("font-size", "12px")
        .style("font-weight", "bold")
        .style("alignment-baseline", "middle");
    });
  }

  checkValues() {
    this.multi = [];
    if (this.graphData) {
      this.graphData = this.graphData.sort((a, b) => {
        const weekOrMonthA = !this.monthMode
          ? (a as ProfilesGroupedByVulnerabilityLevelDto).week
          : (a as ProfilesGroupedByPersonalityVarDto).month;

        const weekOrMonthB = !this.monthMode
          ? (b as ProfilesGroupedByVulnerabilityLevelDto).week
          : (b as ProfilesGroupedByPersonalityVarDto).month;

        const propertyA = weekOrMonthA;
        const propertyB = weekOrMonthB;

        return propertyA - propertyB; // Ordena de menor a mayor
      });
    }

    if (!this.isVulnerability) {
      const highLevelSeries = { name: "Alto Nivel", series: [] };
      const midhighLevelSeries = { name: "Nivel Medio-Alto", series: [] };
      const midlowLevelSeries = { name: "Nivel Medio-Bajo", series: [] };
      const lowLevelSeries = { name: "Bajo Nivel", series: [] };

      // Recorre los datos y asigna valores a cada serie
      this.graphData.map((itm: ProfilesGroupedByPersonalityVarDto) => {
        highLevelSeries.series.push({
          name: this.getMonthName(itm.month),
          value: itm.highLevelTotalProfileCount,
        });

        midhighLevelSeries.series.push({
          name: this.getMonthName(itm.month),
          value: itm.midhighLevelTotalProfileCount,
        });

        midlowLevelSeries.series.push({
          name: this.getMonthName(itm.month),
          value: itm.midlowLevelTotalProfileCount,
        });

        lowLevelSeries.series.push({
          name: this.getMonthName(itm.month),
          value: itm.lowLevelTotalProfileCount,
        });
      });

      // Añade las 4 series al array `multi`
      this.multi.push(
        highLevelSeries,
        midhighLevelSeries,
        midlowLevelSeries,
        lowLevelSeries
      );
    } else {
      if (this.isLinear) {
        const number = { name: "Número empleados", series: [] };
        this.graphData.map(
          (itm: ProfilesGroupedByImprovedVulnerabilityLevelDto) => {
            number.series.push({
              name: this.getMonthName(itm.month),
              value: itm.profileCount,
            });
          }
        );
        this.multi.push(number);
      } else {
        const highLevelSeries = { name: "Alto Nivel", series: [] };
        const midLevelSeries = { name: "Nivel Medio", series: [] };
        const lowLevelSeries = { name: "Bajo Nivel", series: [] };
        // Recorre los datos y asigna valores a cada serie
        this.graphData.map((itm: ProfilesGroupedByVulnerabilityLevelDto) => {
          highLevelSeries.series.push({
            name: this.getMonthName(itm.week),
            value: itm.highVulnerabilityTotalProfileCount,
          });

          midLevelSeries.series.push({
            name: this.getMonthName(itm.week),
            value: itm.mediumVulnerabilityTotalProfileCount,
          });

          lowLevelSeries.series.push({
            name: this.getMonthName(itm.week),
            value: itm.lowVulnerabilityTotalProfileCount,
          });
        });

        this.multi.push(highLevelSeries, midLevelSeries, lowLevelSeries);
      }
    }
  }

  getMonthName(monthNumber: number): string {
    const months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];

    const monthIndex = monthNumber - 1;

    return this.monthMode
      ? months[monthIndex] || "Mes inválido"
      : "Semana " + monthNumber;
  }

  formatInteger(value: any): string {
    return Math.round(value).toString(); // Convertimos el valor a entero
  }
}
