<ng-container *ngIf="adminDataGraphs">
  <nb-tabset fullWidth class="w-100" [ngClass]="{ 'noMenuAdjust': !isMenuVisible }" (changeTab)="onTabChange($event)">
    <!-- ############ APARTADO VULNERABILIDAD ############ -->
    <nb-tab tabTitle="Vulnerabilidad" class="no-horizontal-overflow">

      <!-- Contenedor principal, 3 columnas -->
      <div class="row" *ngIf="!adminDataGraphs.hideVulnerabilityData; else modalMessage">

        <!-- Tarta cuando está responsive, debe aparecer como primer elemento -->
        <div class="col-12 tartaPrimeraResponsive">
          <div id="central-pie-container" class="d-flex flex-column h-100" #eggContainer>
            <span class="font-weight-bold tabTitle">{{'Perfiles por vulnerabilidad'}}</span>

            <ngx-dashboard-pie-graph [isVulnerability]="true" [isCentral]="true" [colorScheme]="colorScheme"
              [graphData]="adminDataGraphs.profilesGroupedByVulnerabilityLevel"
              [mainContainerId]="'profilesGroupedByVulnerabilityLevelResponsiveOnly'"
              class="w-100 h-100 pieVisibilidad">
            </ngx-dashboard-pie-graph>
          </div>
        </div>

        <!-- Columna izquierda -->
        <div class="col-12 col-lg-4 d-flex flex-column justify-content-center">

          <!-- Dos tarjetas -->
          <nb-card
            (click)="openBigGraphModal(adminDataGraphs.profilesGroupedByDepartmentAndVulnerabilityLevel, true, 'Vulnerabilidad por Departamento', 'bar', false, true, '*Número de empleados y su vulnerabilidad por departamento')">
            <span>{{'Vulnerabilidad por departamento'}}</span>
            <ngx-bar-chart [colorScheme]="colorScheme"
              [graphData]="adminDataGraphs.profilesGroupedByDepartmentAndVulnerabilityLevel" [isVulnerability]="true"
              [mainContainerId]="'DepartmentAndVulnerabilityLevel'" class="w-100 h-100">
            </ngx-bar-chart>
          </nb-card>

          <nb-card
            (click)="openBigGraphModal(adminDataGraphs.profilesGroupedByPositionCompanyAndVulnerabilityLevel, true, 'Vulnerabilidad por Cargo', 'bar', false, true, '*Número de empleados y su vulnerabilidad por cargo')">
            <span>{{'Vulnerabilidad por cargo'}}</span>
            <ngx-bar-chart [colorScheme]="colorScheme"
              [graphData]="adminDataGraphs.profilesGroupedByPositionCompanyAndVulnerabilityLevel"
              [isVulnerability]="true" [mainContainerId]="'PositionCompanyAndVulnerabilityLevel'" class="w-100 h-100">
            </ngx-bar-chart>
          </nb-card>

          <nb-card
            (click)="openBigGraphModal(adminDataGraphs.improvedVulnerabilityLevelTimeline, true, 'Nº de empleados con reducción de vulnerabilidad', 'multipleLine', true, true, '*Número de empleados que redujeron su vulnerabilidad respecto del mes anterior', false)">
            <span>{{'Nº de empleados con reducción de vulnerabilidad'}}</span>
            <ngx-multiple-linear [colorScheme]="colorScheme"
              [graphData]="adminDataGraphs.improvedVulnerabilityLevelTimeline" [isVulnerability]="true"
              [isLinear]="true" [mainContainerId]="'improvedVulnerabilityLevelTimeline'" class="w-100 h-100">
            </ngx-multiple-linear>
          </nb-card>

          <nb-card *ngIf="adminDataGraphs.profilesGroupedByAttackSubTypeAndVulnerabilityLevel.length !== 0"
            (click)="openBigGraphModal(adminDataGraphs.profilesGroupedByAttackSubTypeAndVulnerabilityLevel, true,
            'Vulnerabilidad por Tipo de ataque', 'bar', false, true, '*Número de empleados y su vulnerabilidad por tipo de ataque de la campaña recibida')">
            <span>{{'Vulnerabilidad por tipo de ataque'}}</span>
            <ngx-bar-chart [colorScheme]="colorScheme"
              [graphData]="adminDataGraphs.profilesGroupedByAttackSubTypeAndVulnerabilityLevel" [isVulnerability]="true"
              [mainContainerId]="'AttackSubTypeAndVulnerabilityLevel'" class="w-100 h-100">
            </ngx-bar-chart>
          </nb-card>
        </div>

        <!-- Columna central -->
        <div class="col-12 col-lg-4 tartaCentralResponsive">
          <div id="central-pie-container" class="d-flex flex-column h-100" #eggContainer>
            <span class="font-weight-bold tabTitle">{{'Perfiles por vulnerabilidad'}}</span>

            <ngx-dashboard-pie-graph [isVulnerability]="true" [isCentral]="true" [colorScheme]="colorScheme"
              [graphData]="adminDataGraphs.profilesGroupedByVulnerabilityLevel"
              [mainContainerId]="'profilesGroupedByVulnerabilityLevel'" class="w-100 h-100 pieVisibilidad">
            </ngx-dashboard-pie-graph>
          </div>
        </div>

        <!-- Columna derecha -->
        <div class="col-12 col-lg-4 d-flex flex-column justify-content-center">
          <nb-card
            (click)="openBigGraphModal(adminDataGraphs.globalVulnerabilityLevelTimeline, true, 'Línea de tiempo Vulnerabilidad', 'multipleLine', false, false, '*Nº de empleados cuya vulnerabilidad se ha actualizado semanalmente')">
            <span>{{'Timeline Vulnerabilidad'}}</span>
            <ngx-multiple-linear [colorScheme]="colorScheme"
              [graphData]="adminDataGraphs.globalVulnerabilityLevelTimeline" [monthMode]="false"
              [isVulnerability]="true" [mainContainerId]="'globalVulnerabilityLevelTimeline'" class="w-100 h-100">
            </ngx-multiple-linear>
          </nb-card>

          <nb-card
            (click)="openBigGraphModal(adminDataGraphs.profilesGroupedByAgeAndVulnerabilityLevel, true, 'Vulnerabilidad por Edad', 'bar', false, true, '*Número de empleados y su vulnerabilidad por edad')">
            <span>{{'Vulnerabilidad por edad'}}</span>
            <ngx-bar-chart [colorScheme]="colorScheme"
              [graphData]="adminDataGraphs.profilesGroupedByAgeAndVulnerabilityLevel" [isVulnerability]="true"
              [mainContainerId]="'AgeAndVulnerabilityLevel'" class="w-100 h-100">
            </ngx-bar-chart>
          </nb-card>

          <nb-card
            (click)="openBigGraphModal(adminDataGraphs.worseVulnerabilityLevelTimeline, true, 'Nº de empleados con aumento de vulnerabilidad', 'multipleLine', true, true, '*Número de empleados que aumentaron su vulnerabilidad respecto del mes anterior', false)">
            <span>{{'Nº Empleados con aumento de vulnerabilidad'}}</span>
            <ngx-multiple-linear [colorScheme]="colorScheme"
              [graphData]="adminDataGraphs.worseVulnerabilityLevelTimeline" [isVulnerability]="true" [isLinear]="true"
              [mainContainerId]="'worseVulnerabilityLevelTimeline'" class="w-100 h-100">
            </ngx-multiple-linear>
          </nb-card>

          <nb-card
            (click)="openBigGraphModal(adminDataGraphs.profilesGroupedByHiredDateAndVulnerabilityLevel, true, 'Vulnerabilidad por Antigüedad', 'bar', false, true, '*Número de empleados y su vulnerabilidad por antigüedad en la empresa')">
            <span>{{'Vulnerabilidad por antigüedad'}}</span>
            <ngx-bar-chart [colorScheme]="colorScheme"
              [graphData]="adminDataGraphs.profilesGroupedByHiredDateAndVulnerabilityLevel" [isVulnerability]="true"
              [mainContainerId]="'HiredDateAndVulnerabilityLevel'" class="w-100 h-100">
            </ngx-bar-chart>
          </nb-card>
        </div>

      </div>

    </nb-tab>

    <!-- ############ APARTADO BIS ############ -->
    <nb-tab tabTitle="BIS" class="no-horizontal-overflow">

      <div class="row" *ngIf="!adminDataGraphs.hidePersonalityData; else modalMessage">

        <!-- Tarta cuando está responsive, debe aparecer como primer elemento -->
        <div class="col-12 tartaPrimeraResponsive">
          <div id="central-pie-container" class="d-flex flex-column h-100" #eggContainer>
            <span class="font-weight-bold">{{'Personalidad Global - BIS'}}</span>
            <ngx-dashboard-pie-graph [isVulnerability]="false" [isCentral]="true" [colorScheme]="colorScheme"
              [graphData]="adminDataGraphs.profilesGroupedByBisLevel"
              [mainContainerId]="'profilesGroupedByBisLevelResponsive'" class="w-100 h-100 pieAltoLargo">
            </ngx-dashboard-pie-graph>
          </div>
        </div>

        <!-- Columna izquierda -->
        <div class="col-12 col-lg-4 ">
          <nb-card
            (click)="openBigGraphModal(adminDataGraphs.profilesGroupedByDepartmentBisLevel, false, 'BIS por Departamento', 'bar', false, true, '*Número de empleados y su personalidad por departamento')">
            <span>{{'Departamento'}}</span>
            <!-- BIS Department -->
            <!-- <ngx-accumulated-bars-graph [colorScheme]="colorScheme" [isVulnerability]="false"
              [graphData]="adminDataGraphs.profilesGroupedByDepartmentBisLevel"></ngx-accumulated-bars-graph> -->
            <ngx-bar-chart [colorScheme]="colorScheme" [graphData]="adminDataGraphs.profilesGroupedByDepartmentBisLevel"
              [isVulnerability]="false" [mainContainerId]="'profilesGroupedByDepartmentBisLevel'" class="w-100 h-100">
            </ngx-bar-chart>
          </nb-card>
          <nb-card
            (click)="openBigGraphModal(adminDataGraphs.profilesGroupedByPositionCompanyBisLevel, false, 'BIS por Cargo', 'bar', false, true, '*Número de empleados y su personalidad por cargo')">
            <!-- BIS Position -->
            <span>{{'Cargo'}}</span>
            <ngx-bar-chart [colorScheme]="colorScheme"
              [graphData]="adminDataGraphs.profilesGroupedByPositionCompanyBisLevel" [isVulnerability]="false"
              [mainContainerId]="'profilesGroupedByPositionCompanyBisLevel'" class="w-100 h-100">
            </ngx-bar-chart>
          </nb-card>
        </div>

        <!-- Columna central -->
        <div class="col-12 col-lg-4 tartaCentralResponsive">
          <div id="central-pie-container" class="d-flex flex-column h-100" #eggContainer>
            <span class="font-weight-bold">{{'Personalidad Global - BIS'}}</span>
            <ngx-dashboard-pie-graph [isVulnerability]="false" [isCentral]="true" [colorScheme]="colorScheme"
              [graphData]="adminDataGraphs.profilesGroupedByBisLevel" [mainContainerId]="'profilesGroupedByBisLevel'"
              class="w-100 h-100 pieAltoLargo">
            </ngx-dashboard-pie-graph>
          </div>
        </div>

        <!-- Columna derecha -->
        <div class="col-12 col-lg-4 d-flex flex-column justify-content-center">
          <nb-card
            (click)="openBigGraphModal(adminDataGraphs.bisLevelTimeline, false, 'Línea de tiempo BIS', 'multipleLine', false, true, '*Número de empleados y su la evolución de su personalidad por meses')">
            <span>{{'Timeline BIS'}}</span>
            <!-- <ngx-dashboard-area-chart [colorScheme]="colorScheme" [graphData]="adminDataGraphs.bisLevelTimeline"></ngx-dashboard-area-chart> -->
            <!-- <ngx-line-chart [colorScheme]="colorScheme" [graphData]="adminDataGraphs.bisLevelTimeline"></ngx-line-chart>  -->
            <ngx-multiple-linear [colorScheme]="colorScheme" [graphData]="adminDataGraphs.bisLevelTimeline"
              [mainContainerId]="'bisLevelTimeline'" class="w-100 h-100">
            </ngx-multiple-linear>
          </nb-card>
          <!-- <nb-card>
            <span>{{'Gráfico 6'}}</span>
            <ngx-dashboard-pie-graph [isCentral]="false" [colorScheme]="colorScheme"
              [graphData]="adminDataGraphs.profilesGroupedByVulnerabilityLevel"></ngx-dashboard-pie-graph>
          </nb-card>
          <nb-card>
            <span>{{'Gráfico 5'}}</span>
            <ngx-accumulated-bars-graph [colorScheme]="colorScheme" [isVulnerability]="false"></ngx-accumulated-bars-graph>
          </nb-card> -->
        </div>

      </div>
    </nb-tab>

    <!-- ############ APARTADO BAS ############ -->
    <nb-tab tabTitle="BAS">
      <div class="row" *ngIf="!adminDataGraphs.hidePersonalityData; else modalMessage">

        <!-- Tarta cuando está responsive, debe aparecer como primer elemento -->
        <div class="col-12 tartaPrimeraResponsive">
          <div id="central-pie-container" class="d-flex flex-column h-100" #eggContainer>
            <span class="font-weight-bold">{{'Personalidad Global - BAS'}}</span>
            <ngx-dashboard-pie-graph [isVulnerability]="false" [isCentral]="false" [colorScheme]="colorScheme"
              [graphData]="adminDataGraphs.profilesGroupedByBasLevel"
              [mainContainerId]="'profilesGroupedByBasLevelResponsive'" class="w-100 h-100 pieAltoLargo">
            </ngx-dashboard-pie-graph>
          </div>
        </div>

        <!-- Columna izquierda -->
        <!-- <div class="col-12 col-lg-4 d-flex flex-column justify-content-center"> -->
        <div class="col-12 col-lg-4">
          <nb-card
            (click)="openBigGraphModal(adminDataGraphs.profilesGroupedByDepartmentBasLevel, false, 'BAS por Departamento', 'bar', false, true, '*Número de empleados y su personalidad por departamento')">
            <!-- BAS Department -->
            <span>{{'Departamento'}}</span>
            <!-- <ngx-accumulated-bars-graph [colorScheme]="colorScheme" [isVulnerability]="false"
              [graphData]="adminDataGraphs.profilesGroupedByDepartmentBasLevel"></ngx-accumulated-bars-graph> -->
            <ngx-bar-chart [colorScheme]="colorScheme" [graphData]="adminDataGraphs.profilesGroupedByDepartmentBasLevel"
              [isVulnerability]="false" [mainContainerId]="'profilesGroupedByDepartmentBasLevel'" class="w-100 h-100">
            </ngx-bar-chart>
          </nb-card>
          <nb-card
            (click)="openBigGraphModal(adminDataGraphs.profilesGroupedByPositionCompanyBasLevel, false, 'BAS por Cargo', 'bar', false, true, '*Número de empleados y su personalidad por cargo')">
            <!-- BAS Position -->
            <span>{{'Cargo'}}</span>
            <!-- <ngx-accumulated-bars-graph [colorScheme]="colorScheme" [isVulnerability]="false"
              [graphData]="adminDataGraphs.profilesGroupedByPositionCompanyBasLevel"></ngx-accumulated-bars-graph> -->
            <ngx-bar-chart [colorScheme]="colorScheme"
              [graphData]="adminDataGraphs.profilesGroupedByPositionCompanyBasLevel" [isVulnerability]="false"
              [mainContainerId]="'profilesGroupedByPositionCompanyBasLevel'" class="w-100 h-100">
            </ngx-bar-chart>
          </nb-card>
        </div>

        <!-- Columna central -->
        <div class="col-12 col-lg-4 tartaCentralResponsive">
          <div id="central-pie-container" class="d-flex flex-column h-100" #eggContainer>
            <span class="font-weight-bold">{{'Personalidad Global - BAS'}}</span>
            <ngx-dashboard-pie-graph [isVulnerability]="false" [isCentral]="true" [colorScheme]="colorScheme"
              [graphData]="adminDataGraphs.profilesGroupedByBasLevel" [mainContainerId]="'profilesGroupedByBasLevel'"
              class="w-100 h-100 pieAltoLargo">
            </ngx-dashboard-pie-graph>
          </div>
        </div>

        <!-- Columna derecha -->
        <div class="col-12 col-lg-4 d-flex flex-column justify-content-center">
          <!-- <nb-card (click)="openBigGraphModal(adminDataGraphs.basLevelTimeline, false, 'Línea de tiempo BAS', 'multipleLine')">
            <span>{{'Timeline BAS'}}</span>
          </nb-card> -->
          <nb-card
            (click)="openBigGraphModal(adminDataGraphs.basLevelTimeline, false, 'Línea de tiempo BAS', 'multipleLine', false, true, '*Número de empleados y su la evolución de su personalidad por meses')">
            <!-- <ngx-dashboard-area-chart [colorScheme]="colorScheme" [graphData]="adminDataGraphs.bisLevelTimeline"></ngx-dashboard-area-chart> -->
            <!-- <ngx-line-chart [colorScheme]="colorScheme" [graphData]="adminDataGraphs.basLevelTimeline"></ngx-line-chart>  -->
            <span>{{'Timeline BAS'}}</span>

            <ngx-multiple-linear [colorScheme]="colorScheme" [graphData]="adminDataGraphs.basLevelTimeline"
              [mainContainerId]="'basLevelTimeline'" class="w-100 h-100">
            </ngx-multiple-linear>
          </nb-card>
          <!-- <nb-card>
              <span>{{'Gráfico 2'}}</span>
              <ngx-dashboard-area-chart [colorScheme]="colorScheme"></ngx-dashboard-area-chart>
            </nb-card> -->
        </div>

      </div>

    </nb-tab>

    <!-- ############ APARTADO NC ############ -->
    <nb-tab tabTitle="NC">

      <div class="row" *ngIf="!adminDataGraphs.hidePersonalityData; else modalMessage">

        <!-- Tarta cuando está responsive, debe aparecer como primer elemento -->
        <div class="col-12 tartaPrimeraResponsive">
          <div id="central-pie-container" class="d-flex flex-column h-100" #eggContainer>
            <span class="font-weight-bold">{{'Personalidad Global - NC'}}</span>
            <ngx-dashboard-pie-graph [isCentral]="true" [colorScheme]="colorScheme"
              [graphData]="adminDataGraphs.profilesGroupedByNcLevel"
              [mainContainerId]="'profilesGroupedByNcLevelResponsive'" class="w-100 h-100 pieAltoLargo">
            </ngx-dashboard-pie-graph>

          </div>
        </div>

        <!-- Columna izquierda -->
        <!-- <div class="col-12 col-lg-4 d-flex flex-column justify-content-center"> -->
        <div class="col-12 col-lg-4">
          <nb-card
            (click)="openBigGraphModal(adminDataGraphs.profilesGroupedByDepartmentNcLevel, false, 'NC por Departatamento', 'bar', false, true, '*Número de empleados y su personalidad por departamento')">
            <!-- NC Department -->
            <span>{{'Departamento'}}</span>
            <!-- <ngx-accumulated-bars-graph [colorScheme]="colorScheme" [isVulnerability]="false"
              [graphData]="adminDataGraphs.profilesGroupedByDepartmentNcLevel"></ngx-accumulated-bars-graph> -->
            <ngx-bar-chart [colorScheme]="colorScheme" [graphData]="adminDataGraphs.profilesGroupedByDepartmentNcLevel"
              [isVulnerability]="false" [mainContainerId]="'profilesGroupedByDepartmentNcLevel'" class="w-100 h-100">
            </ngx-bar-chart>
          </nb-card>
          <nb-card
            (click)="openBigGraphModal(adminDataGraphs.profilesGroupedByPositionCompanyNcLevel, false, 'NC por Cargo', 'bar', false, true, '*Número de empleados y su personalidad por cargo')">
            <!-- NC Position -->
            <span>{{'Cargo'}}</span>
            <!-- <ngx-accumulated-bars-graph [colorScheme]="colorScheme" [isVulnerability]="false"
              [graphData]="adminDataGraphs.profilesGroupedByPositionCompanyNcLevel"></ngx-accumulated-bars-graph> -->
            <ngx-bar-chart [colorScheme]="colorScheme"
              [graphData]="adminDataGraphs.profilesGroupedByPositionCompanyNcLevel" [isVulnerability]="false"
              [mainContainerId]="'profilesGroupedByPositionCompanyNcLevel'" class="w-100 h-100">
            </ngx-bar-chart>
          </nb-card>
        </div>

        <!-- Columna central -->
        <div class="col-12 col-lg-4 tartaCentralResponsive">
          <div id="central-pie-container" class="d-flex flex-column h-100" #eggContainer>
            <span class="font-weight-bold">{{'Personalidad Global - NC'}}</span>
            <ngx-dashboard-pie-graph [isCentral]="true" [colorScheme]="colorScheme"
              [graphData]="adminDataGraphs.profilesGroupedByNcLevel" [mainContainerId]="'profilesGroupedByNcLevel'"
              class="w-100 h-100 pieAltoLargo">
            </ngx-dashboard-pie-graph>

          </div>
        </div>

        <!-- Columna derecha -->
        <div class="col-12 col-lg-4 d-flex flex-column justify-content-center">
          <nb-card
            (click)="openBigGraphModal(adminDataGraphs.ncLevelTimeline, false, 'Línea de tiempo NC', 'multipleLine', false, true, '*Número de empleados y su la evolución de su personalidad por meses')">
            <span>{{'Timeline NC'}}</span>
            <ngx-multiple-linear [colorScheme]="colorScheme" [graphData]="adminDataGraphs.ncLevelTimeline"
              [mainContainerId]="'ncLevelTimeline'" class="w-100 h-100">
            </ngx-multiple-linear>

          </nb-card>
          <!-- <nb-card>
            <span>{{'Gráfico 2'}}</span>

          </nb-card>
          <nb-card>
            <span>{{'Gráfico 6'}}</span>

          </nb-card> -->
        </div>

      </div>

    </nb-tab>
  </nb-tabset>
</ng-container>

<ng-template #graphModal let-ref="dialogRef" let-data let-itm="itm">
  <nb-card class="big-modal">
    <ngx-big-graph class="h-100 w-100 d-flex" [graphData]="data.graphData" [title]="data.title"
      [graphExplanation]="data.graphExplanation" [isVulnerability]="data.isVulnerability" [colorScheme]="colorScheme"
      (closeModal)="onModalClose(ref)" [graphType]="data.graphType" [isLinear]="data.isLinear"
      [monthMode]="data.monthMode" [showLegend]="data.showLegend">
    </ngx-big-graph>
  </nb-card>
</ng-template>

<ng-template #modalMessage>
  <div class="h-100 d-flex align-items-center justify-content-center">
    <nb-card class="">
      <nb-card-body>
        <div class="p-5 h-100 d-flex align-items-center justify-content-center flex-column">
          <h3 class="d-flex title-modal justify-content-center align-items-center">
            {{ '¡Ey!' }}
          </h3>
          <div class="mt-4 d-flex justify-content-center">
            {{ 'Los niveles de vulnerabilidad de tu organizacion todavía no están disponibles. Se mostrarán los datos
            despues de primera simulación.'}}
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </div>
</ng-template>