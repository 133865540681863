import { NgModule } from '@angular/core';
import { NbAccordionModule, NbActionsModule, NbAlertModule, NbButtonModule, NbCardModule, NbCheckboxModule, NbIconModule, NbInputModule, NbMenuModule, NbPopoverComponent, NbPopoverModule, NbSelectModule, NbSpinnerModule, NbTabsetComponent, NbTabsetModule, NbTagModule, NbTooltipModule } from '@nebular/theme';


import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CommonModule } from '@angular/common';
import { GeneralComponentsModule } from '../../components/components.module';
import { ReactiveFormsModule } from '@angular/forms';
import { UsersTableComponent } from './users-table/users-table.component';



const COMPONENTS = [
    UsersTableComponent
];

@NgModule({
  imports: [
    NbTabsetModule,
    NbCardModule,
    NbSpinnerModule,
    NbMenuModule,
    NbButtonModule,
    NbTagModule,
    NbPopoverModule,
    NbAlertModule,
    NbSelectModule,
    NbActionsModule,
    NbAccordionModule,
    ReactiveFormsModule,
    NbInputModule,
    NbCheckboxModule,
    NbTooltipModule,
    NbIconModule,
    NgxDatatableModule,
    CommonModule,
    GeneralComponentsModule
  ],
  declarations: [
    ...[COMPONENTS]
  ],
  providers: [
  ],
  exports:[...[COMPONENTS]]
})
export class ComponentsUserdModule {
}
